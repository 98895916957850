@import "../../../style/app";

.intro {
  padding: 0 120px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  h1 {
    margin-bottom: 19px;
  }
}

.medicationMain {
  h2 {
    margin: 0 0 25px;
  }

  :global .row {
    margin-top: 60px;
  }

  :global .newItem {
    margin-left: 20px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }
}
