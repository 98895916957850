.input {
  font-family: 'FiraSans';
}

.otpText {
  font-weight: 700;
  font-size: 16px;
  margin-top: 43px;
  width: 267px;
  margin-left: 5px;
}

.otpInput {
  display: flex;
}

.otp {
  text-align: left;

  input[type='text'] {
    width: 50px;
    height: 50px;
    border-radius: 0;
    margin-right: -1px;
    padding: 5px 0px 5px 20px;
  }

  input[type='text']:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  input[type='text']:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

}

.otpAction {
  margin-top: -15px;
  margin-bottom: 40px;

  :global .btn {
    width: 306px;
    margin-top: 25px;
  }
}