@use "sass:math";
@use "./colors";

$rem-size: math.div(1rem, 16px);

@mixin tableBase() {
  border: solid 2px colors.theme-color(secondary);
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  padding: 25px 25px 40px;
  width: 100%;

  tbody tr:nth-child(2n + 1) {
    background-color: change-color($color: colors.theme-color(secondary), $alpha: .02);
  }

  th {
    font-weight: 600;
    white-space: nowrap;
  }

  th, td {
    padding: 24px 10px;
    border-bottom: 2px solid change-color(colors.theme-color(secondary), $alpha: .25);
    vertical-align: top;
  }
}