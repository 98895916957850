@import "../../style/app";

.tileList {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 35px;
  row-gap: 30px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 14px;
  }
}
