@use "../../style/colors";

.medicationForm {
  border: solid 2px colors.theme-color(secondary);
  border-radius: 5px;
  padding: 20px 40px 35px 30px;

  h2 {
    margin-top: 0;
  }

  label {
    width: 100%;
  }

  input {
    width: 100%;
  }

  :global .row {
    margin-top: 20px;
  }

  :global .btn {
    width: 100%;
  }
}

.formLabel {
  font-weight: 600;
}

.formInput {
  margin: 10px 0;
}