@use "../../../../style/colors.scss";

.permissionScreen {
  text-align: center;
}

.logo {
  margin-bottom: 75px;
}

.title {
  margin-bottom: 14px;
}

.content {
  font-family: "FiraSans";
  margin-bottom: 40px;

  strong {
    font-weight: 700;
    color: #384b96;
  }
}

.grant {
  margin-bottom: 75px;
}
