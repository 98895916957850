@use "../../style/mixins";
@import "../../style/app";

.page {
  margin-bottom: 75px;
  overflow-x: hidden;
}

.center {
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

.logoTop {
  padding-top: 63px;
}

.wrapHeader {
  overflow: hidden;
}

.logo {
  cursor: pointer;
}

.layoutRadius {
  background-color: #f1f2f8;
  display: block;
  padding: 0 100px 70px;
  margin: 0 -100px 50px;
}

.header {
  @extend .layoutRadius;
  border-bottom-left-radius: 50% 70px;
  border-bottom-right-radius: 50% 70px;
  .top {
    padding-top: 150px;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }
  }
}

.dashboardHeader {
  @extend .header;

  @include media-breakpoint-up(sm) {
    .top {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .top .callToActionColumn {
        margin-bottom: 0;
    }
  }
}

.top {
  justify-content: center;
  text-align: center;
}

.viewMore {
  margin-top: -50px;
}

.viewMoreDashboard {
  margin-top: 70px;
}

.chooseAPgo {
  font-size: 14px * mixins.$rem-size;
  margin-top: 30px;
  border-radius: 50px;
  text-transform: none;
  padding-left: 46px;
  background-image: url('../../style/img/check-circle.svg');
  background-repeat: no-repeat;
  background-position: 11px 8px;
  white-space: nowrap;
}

.callToActionColumn {
  margin-bottom: 30px;
  text-align: left;
  min-height: 75px;

  @include media-breakpoint-up(sm) {
    text-align: right;
    margin-top: 20px;
  }
}

.top .callToActionColumn {
  @include media-breakpoint-up(sm) {
    margin-bottom: 70px;
  }
}

.rectangle {
  margin-top: 90px;
}

.middleBottom {
  @extend .layoutRadius;
  border-top-left-radius: 50% 70px;
  border-top-right-radius: 50% 70px;
}

.footerTop {
  padding-top: 20px;
  border-bottom: 3px solid #F1F2F8;
}
