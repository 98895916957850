@import "../../style/colors.scss";

.breadcrumb {
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #F1F2F8;
  height: 44px;
  padding: 9px 20px;
  color: #384B96;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;

  &:hover {
    color: #384B96;
    
    span {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 9px;
  }
}