@import "../../style/app";

.formWidth {
  margin-top: 0;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  } 

  img {
    width: auto;
    height: auto;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}
