@import "../../style/app";

$theme: theme-color(cards);

@mixin card($border-color) {
  text-align: center;
  position: relative;
  border: solid 2px theme-color(secondary);
  border-radius: 5px;
  padding: 15px 20px;
  border-color: $border-color;
}

.weight-card {
  @include card(map-get($theme, border-1));
}

.distance-card {
  @include card(map-get($theme, border-2));
}

.heartbeat-card {
  @include card(map-get($theme, border-3));
}

.bloodpressure-card {
  @include card(map-get($theme, border-4));
}

.icon {
  height: 3rem;
  width: auto;
}

.value {
  @extend %h1;

  margin-top: 11px;
}

.label {
  @extend %h3;

  line-height: 14px * $rem-size;
  margin-bottom: 7px;
}

.lastUpdate {
  font-size: 14px * $rem-size;
  color: change-color($color: theme-color(text), $alpha: .85);
  margin-bottom: 15px;
}

.graph svg {
  width: 100%;
  height: auto;
}