@use "sass:math";
@use "./mixins" as base;

%h1 {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 32px * base.$rem-size;
  line-height: math.div(42, 32);
  text-align: center;
}

%h2 {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 24px * base.$rem-size;
  line-height: math.div(34, 24);
}

%h3 {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 16px * base.$rem-size;
  line-height: math.div(26, 16);
}

%h4 {
  font-family: "FiraSans";
  font-size: 16px * base.$rem-size;
  line-height: math.div(26, 16);
}

h1 { @extend %h1; }
h2 { @extend %h2; }
h3 { @extend %h3; }
h4 { @extend %h4; }
