.formWidth {
  width: 683px;
  font-family: 'FiraSans';

  p {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
  }
}
