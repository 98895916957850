.landingScreen {
  text-align: center;

  h1 {
    margin-bottom: 35px;
  }
}

.logo {
  margin-bottom: 75px;
}

.explanation {
  font-family: "FiraSans";
  margin-bottom: 56px;
}

.submit {
  margin-bottom: 122px;

  :global .btn {
    width: 270px;
  }
}