@use "sass:math";
@use "../../style/mixins" as base;
@import "../../style/app";

.tileList {
  margin: 0 auto;
  padding: 20px 0 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 30px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
  
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 0px;
    padding: 0px;
  }
}

.title {
  @extend %h2;

  margin-bottom: 30px;
}