@import "../../style/app.scss";

.userInformation {
  margin: 0 auto;
  padding: 20px 0 0;
  display: flex;
  text-align: left;

  @include media-breakpoint-up(lg) {
    padding-top: 5px;
  }
}

.avatar {
  flex: 0 0 65px;
}

.listItem {
  margin-left: 7px;
  padding: 10px 0px 30px 0px;
}

.listItemTitle {
  font-family: 'Quicksand';
  font-size: 20px;
  font-weight: 700;
}

.listItemInfo {
  padding-left: 16px;
  margin-top: 8px;
  font-weight: 400;
  color: theme-color(primary);
}

.listItemText {
  margin-top: 10px;
  margin-left: 20px;
}
