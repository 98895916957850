@import "../../style/app";

.formWidth {
  font-family: 'Quicksand';
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  } 

  p {
    font-family: 'FiraSans';
    font-weight: 400;
    font-size: 16px;
  }

  input[type='radio'] {
    margin-right: 10px;
  }

  label {
    @extend %h3;

    color: #384b96;
  }

  hr {
    margin: 0;
  }
}

.content {
  width: 418px;
}

.authenticator {
  border-bottom: 2px solid rgba(56, 75, 150, 0.5);
  padding: 3px 0px 5px 0px;
  margin-top: 12px;
  text-align: left;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.area2fa {
  margin-left: 48px;

  p {
    text-align: left;
    width: 267px;
  }

  input {
    height: 50px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 306px;
    }
  }
}

.area2faContent {
  display: grid;

  li {
    font-family: 'FiraSans';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    text-align: start;
    padding: 0px 0px 2px 0px;
    
    &:first-child {
      list-style-type: none;
      margin-bottom: 1rem;
      counter-reset: list-item;
    }
  }

  :global .btn {
    width: 100%;
    margin-top: 25px;

    @include media-breakpoint-up(sm) {
      width: 306px;
    }
  }
}

.area2faCode {
  display: grid;
}

.area2faAuthenticator {
  display: flex;
}
