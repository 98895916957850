@use "sass:math";
@import "../../style/app";
@import "../../style/mixins";
@import "../../style/colors";

$tile-background: change-color(theme-color(secondary), $alpha: .5) !default;

.link {
  text-decoration: none;
  transition: transform .2s;

  &:hover {
    color: inherit;
    background-color: #4CC5CF;
    border-radius: 5px;
  }

  .title, .note {
    color: theme-color(text);
  }
}

.tile {
  border-radius: 5px;
  background-color: $tile-background;
  padding: 27px 16px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;

  svg {
    @include media-breakpoint-down(sm) {
      width: 100px;
      margin-left: 16px;
    }
  }
}

.approved {
  position: absolute;
  top: 0;
  right: 16px;
}

.icon {
  width: 132px;
  height: 126px;
  margin-bottom: 25px;
}

.title {
  font-family: "Quicksand";
  text-transform: uppercase;
  font-size: 18px * $rem-size;
  font-weight: 700;
  line-height: math.div(34, 18);
  letter-spacing: 0.05em;
  text-align: center;
  @include media-breakpoint-down(sm) {
    line-height: 1.4;
    font-size: 1rem;
  }
}

.note {
  font-family: "FiraSans";
  font-weight: 400;
  font-size: 14px * $rem-size;
  line-height: math.div(25, 14);
  opacity: .85;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.interactive {
  height: 21px;
  font-size: 14px;
  background-color: theme-color(highlight);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-weight: 600;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
