.title {
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 700;
}

.info {
    width: 683px;
}

.orderList {
    color: #384B96;

    ul li::marker  {
        color: #00ADBA;
    }  
}


.list {
    font-family: 'FiraSans';
    font-size: 16px;
    font-weight: 400;
}