.modal {
    position: fixed;
    width: 100vw;
    inset: 0;
}

.modalContainer {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.modalInner {
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #FFF;
    border-radius: 5px;
    border: 2px solid #00ADBA;
    padding: 30px 35px;
    text-align: center;
    margin: 0 30px;
    z-index: 2;
}

.modalCloseButton {
    border: 1px solid #F1F2F8;
    background-color: #FFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    outline: 0;
    line-height: 38px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 4px rgba(241,241,241,0.8);
    box-shadow: 0 0 10px 4px rgba(241,241,241,0.8);
    position: absolute;
    top: -20px;
    right: -20px;
    color: #384B96;
    fill: #384B96;
    transition: .15s ease-in-out all;
}

.modalCloseButton:hover {
    background-color: #384B96;
    fill: #FFF;
}

.modalDocumentBg {
    position: fixed;
    width: 100vw;
    inset: 0;
    background-color: rgba(241,241,241,0.8);
    z-index: 1;
    backdrop-filter: blur(2px);
}