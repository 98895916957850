@use "../../../style/mixins.scss";
@import "../../../style/app";

.bold {
  font-family: 'Quicksand';
  font-weight: 700;
}

.maxWidth {
  max-width: 768px;
  text-align: center;
  margin: 0 auto;
}

.title {
  @extend .bold;
  @extend .maxWidth;
  font-size: 48px * mixins.$rem-size;
  background: linear-gradient(103.18deg, #00ADBA 14.59%, #384B96 72.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    line-height: 3.5rem;
    margin-bottom: 2rem
  }
}

.intro {
  @extend .maxWidth;
  margin-top: 5px;
  line-height: 34px;
}

.button {
  @extend .bold;
  font-size: 16px * mixins.$rem-size;
  text-align: center;
  margin: 0 auto;
  margin-top: 38px;
  margin-bottom: 10px;

  button {
    width: 284px;
  }
}

.bottomTitle {
  margin-top: 45px;
}

.text {
  font-weight: 400;
  a {
    text-decoration: none;
    color: #384B96;
  }
}

.middleTitleIntro {
  margin-top: 35px;
  font-weight: 600;
}

.middle {
  margin-bottom: 30px;
}

.bottom {
  margin-bottom: 30px;
  padding-top: 30px;
}

.topFooter {
  min-height: 195px;
}

.topFooterButton {
  @extend .button;
  margin-top: 20px;
}

.bottomFooter {
  padding-top: 40px;
  padding-bottom: 40px;
}
