@use "../../../../style/colors";

.navigation {
  a {
    text-decoration: none;
    color: colors.theme-color(primary);

    &:visited {
      color: colors.theme-color(primary);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.mainHeading {
  margin-top: 40px;
}

.tableHeading {
  margin: 55px 0 20px;
}