@import "../../../../style/app";

.digidPage {
  text-align: center;
  background-color: #FBFBFB;
  min-height: 100vh;

  .intro {
    text-align: center;
    padding: 45px 0;
  }

  svg {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.fakeButton:hover {
  cursor: pointer;
}