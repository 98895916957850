@import "../../style/app";

.list {
  border-radius: 5px;
  border: 2px solid #00ADBA;
}

.listItem {
  margin: 0 17px 10px;
  border-bottom: 2px solid change-color(theme-color(secondary), $alpha: .25);
  padding: 10px 0px 30px 0px;
  text-align: center;
  position: relative;
  
  @include media-breakpoint-up(sm) {
    display: flex;
    text-align: left;

    .listContents {
      flex-grow: 1;
    }
  }

  table {
    border: none;
    padding: 0;
  }
}

.listItem:last-child {
  margin-bottom: 60px;
}

.listItemTitle {
  @extend %h2;

  a {
    color: theme-color(primary);
    text-decoration: none;

    &:visited {
      color: theme-color(primary);
      text-decoration: underline;
    }
  }

  :global .newItem {
    vertical-align: middle;
    margin-left: 20px;
    position: absolute;
    top: 10px;
    right: 0;

    @include media-breakpoint-up(sm) {
      position: static;
    }
  }
}

.listItemInfo {
  font-family: 'FiraSans';
  font-weight: 400;
}

.listItemLogo {
  margin-right: 22px;
  flex-grow: 0;
  flex-shrink: 1;
  padding-bottom: 15px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
  }
}

.listItemModal {
  cursor: pointer;
}

