@use "../../style/colors";
@use "../../style/mixins";

.dossierImporter {
  border: solid 2px colors.theme-color(secondary);
  border-radius: 5px;
  background-color: change-color(colors.theme-color(secondary), $alpha: .03);
  padding: 20px 40px 35px 30px;

  h2 {
    margin-bottom: 21px * mixins.$rem-size;
  }

  label {
    font-family: "FiraSans";
    font-weight: 600;
  }

  :global {
    .row {
      margin-bottom: 33px * mixins.$rem-size;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.submitImportRequest {
  width: 100%;
  background-color: colors.theme-color(primary);
  text-transform: uppercase;
  font-weight: 700;
  
  &:hover {
    background-color: lighten(colors.theme-color(primary), 10%);
  }
}