@import "../../../../style/app";
.intro {
  padding: 0 120px;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  h1 {
    margin-bottom: 19px;
  }
}

.ggzMain {
  h2 {
    margin: 56px 0 18px;
  }
}
