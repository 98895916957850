@use "sass:math";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "./fonts";
@import "./colors";
@import "./mixins";
@import "./typography";

body {
  color: #1d1f25;
  font-family: "FiraSans";
  line-height: math.div(26, 16);
}

a:link {
  color: #384b96;
}

p:last-child {
  margin-bottom: 0;
}

input.form-check-input:checked[type=radio] {
  background-image: url('./img/radio-bg.svg');
  background-position-x: 0.46rem;
  background-size: 14px 14px;
  background-color: #fff;
}

input.form-check-input[type=radio] {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin-top: -0.2em;
  border: 1px solid rgba(0, 0, 0, 0.25);
} 

.form-check-input[type=radio]:checked {
  border-color: rgba(0, 0, 0, 0.25);
}

hr {
  border: 1px solid #384B96;
}

input {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-group-prepend {
  height: 50px;
}

.btn:hover {
  color: white;
}

.btn-primary {
  font-family: "Quicksand";
  font-weight: 700;
  text-transform: uppercase;
  line-height: math.div(35, 16);
  background-color: theme-color(primary);
  transition: color 0s; // prevents flickering
  border: none;

  &:hover, &:active {
    background-color: darken(theme-color(primary), 6%);
  }

  &:link {
    color: white;
  }

  &:disabled, &.inactive {
    cursor: default;
    background-color: mix(theme-color(primary), white, 50%);
    border-color: mix(theme-color(primary), white, 50%);
  }
}

.btn-ghost {
  color: theme-color(primary);
  font-size: 14px * $rem-size;

  &:hover, &:active {
    color: change-color($color: theme-color(primary), $lightness: 50%);
  }
}

table {
  @include tableBase();
}

ul {
  padding-left: 17px;

  li::marker {
    color: theme-color(secondary);
  }
}

.newItem {
  color: white;
  background-color: theme-color(highlight);
  border-radius: 10px;
  padding: 0 5px;
  height: 20px;
  font-family: "Quicksand";
  font-size: 12px * $rem-size;
  text-transform: uppercase;
  font-weight: 700;
}
